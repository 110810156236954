import React from 'react';
import Headroom from 'react-headroom';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import MenuDropDown from './MenuDropdown';
import RouteLink from './RouteLink';

;

const HeaderContainer = styled(Headroom)`
  .headroom--pinned {
    background: ${(props) => props.theme.colors.white};
  }
  background:${(props) => props.theme.colors.white};;
  position: relative;
  width: 100%;
`;

const Header = ({ links, isDesktop, mobileMenuState }) => {

  const navLinks = links.map(({ title, link }) => (
    <RouteLink
      key={title}
      link={link}
      title={title}
      selected={false}
      isDesktop={isDesktop}
    />
  ));

  return (
    <HeaderContainer>
      <Fade top>
        <Flex
          backgroundColor="heading"
          as="nav"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          p={3}
          mx={0}
          role="navigation"
        >
          { isDesktop ? 
            <Flex as='ul' mr={[0, 3, 5]}>{navLinks}</Flex> :
            <MenuDropDown navLinks={navLinks} mobileMenuState={mobileMenuState} />
          }
        </Flex>
      </Fade>
    </HeaderContainer>
  );
}

Header.propTypes = {
  mobileMenuState: PropTypes.objectOf(
    PropTypes.shape({
      mobileMenuOpen: PropTypes.bool,
      toggleMobileMenu: PropTypes.func,
    })
  ),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.number,
    })
  ),
  isDesktop: PropTypes.bool.isRequired
};

export default Header;


