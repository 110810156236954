import { useState, useEffect } from 'react';
/* eslint-disable import/prefer-default-export */

export const MOBILE_BREAKPOINT = '639';

export const useWindowSize = () => {
  const isClient = typeof window !== 'undefined';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
      isDesktop: isClient ? window.innerWidth > MOBILE_BREAKPOINT : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }
    setWindowSize(getSize());
    
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); 

  return windowSize;
}


