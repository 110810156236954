import React from 'react';
import styled from 'styled-components';
import { Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import Slide from 'react-reveal/Slide';

const SectionContainer = styled.div`
  min-height: 100vh;
  min-width: 320px;
  max-width: 1366px;
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  padding: 5em 1em;
  scroll-behavior: smooth;
`;

const Container = ({ children }) => (
  <SectionContainer>{children}</SectionContainer>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

const HeaderLeft = ({ name }) => (
  <Flex style={{ paddingLeft: '30px' }}>
    <Slide left>
      <Text
        as="h3"
        fontSize={[4]}
        color='primary'
        mb={[3]}
      >
        {name}
      </Text>
    </Slide>
  </Flex>
);

const HeaderLeftSmall = ({ name }) => (
  <Flex style={{ paddingLeft: '30px' }}>
    <Text
      as="h4"
      fontSize={[3]}
      color='primary'
      mb={[3]}
    >
      {name}
    </Text>
  </Flex>
);

HeaderLeft.propTypes = {
  name: PropTypes.string.isRequired,
};

HeaderLeftSmall.propTypes = {
  name: PropTypes.string.isRequired,
};

export default {
  Container,
  HeaderLeft,
  HeaderLeftSmall
};
