import React from 'react';
import { Text, Box } from 'rebass/styled-components';
import Fade from 'react-reveal/Fade';

const Footer = () => (
  <Box p={3} backgroundColor="heading" as="footer">
    <Fade>
      <Text as="p" textAlign="center" color="headingSecondary" fontSize={[1, 2]}>
        Amanda Lane - UX Designer - Sydney Australia
      </Text>
    </Fade>
  </Box>
      );

export default Footer;
