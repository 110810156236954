import React from 'react';
import { Button } from 'rebass/styled-components';
import { Link } from "gatsby"
import PropTypes from 'prop-types';
import colors from '../../colors';

const ButtonNav = ({ link, text }) => (
  <Link style={{ color: colors.white, cursor: "pointer" }} to={link}>
    <Button 
      px={4}
      py={3}
      sx={{
      cursor:"pointer",
      marginTop:'8px',
      backgroundColor:'cta',
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        backgroundColor:'ctaHover',
      }
    }}
    >
      {text}
    </Button>
  </Link>
)
    
ButtonNav.propTypes = {
    link: PropTypes.string.isRequired,    
    text: PropTypes.string.isRequired,
};

export default ButtonNav;

