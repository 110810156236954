import React from 'react';
import styled from 'styled-components';
import { Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';
import { MOBILE_BREAKPOINT } from '../hooks/useWindowSize';
import colors from '../../colors';

const HeadingContainer = styled.div`
${({ condensed }) => condensed && `
    @media (min-width: ${MOBILE_BREAKPOINT}px) {
      width: 100vw;
      margin-left: 64px;
      h1 {
        max-width: 340px;
        line-height: 80px;
      }
    }
  `}
    h1 {
      z-index: 1;
      color: ${colors.heading};
      text-align: center;
      @media (min-width: ${MOBILE_BREAKPOINT}px) {
        text-align: left;
      }
    }
${({ variant }) => variant && `
    h1 {
      color: ${colors.primary};
      @media (min-width: ${MOBILE_BREAKPOINT}px) {
        color: ${colors.headingSecondary};
      }
    }
  `}
`;

const Container = styled.div`
    .react-reveal {
      height:200px;
      align-items:center;
      display:flex;
      @media (min-width: ${MOBILE_BREAKPOINT}px) {
        height:500px
      }
    }
`

const Banner = ({ condensed, src, title, variant }) => {
  return (
    <Container>
      <ParallaxProvider>
        <Fade>
          <ParallaxBanner
            className="your-class"
            layers={[{
          amount: 0.2,
          image: src, 
        }]}
            style={{
          height:'100%',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
        }}
          > 
            <Fade
              delay={1000}
            >
              <HeadingContainer condensed={condensed} variant={variant}>
                <Text
                  as="h1"
                  fontSize={[5,6]}
                  px={2}
                >
                  {title}     
                </Text>
              </HeadingContainer>
            </Fade>
          </ParallaxBanner>
        </Fade>
      </ParallaxProvider>
    </Container>
);}

Banner.propTypes = {
    condensed: PropTypes.bool,
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    variant: PropTypes.bool,
};

export default Banner;
