module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#f0e6f6',
  primary: '#000000',
  primaryLight: '#ae66df',
  primaryDark: '#4b007d',
  secondary: '#ff4081',
  secondaryLight: '#ff79b0',
  secondaryDark: '#c60055',
  heading: '#F5F5F5',
  headingSecondary:'#6b5858',
  white: '#FFFFFF',
  cta: '#ea9f5e',
  ctaHover: '#d28f54'
};
